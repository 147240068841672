import { useEffect } from 'react'

// Redirect
const EnPage = () => {
  useEffect(() => {
    window.location.replace('/terms-of-use')
  })

  return null
}

export default EnPage
